import React from 'react'
import styled from 'styled-components'
import { Switch, Redirect, Route } from 'react-router-dom'
import { NotFound, withPageMetadata, metadata } from '@pga/pga-component-library'
import { RedirectToLogin, RedirectToLogout, ImpersonationOverlay } from '../lib/auth'
import Layout from '../components/Layout'
import 'react-instantsearch-theme-algolia/style.css'
import { HomePage, CategoryPage, ArticlePage } from '../components/News'
import IntercomWithAuth from '../components/Intercom'

const MainBox = styled.main`
  height: 80vh;
`

export default () => (
  <MainBox>
    <ImpersonationOverlay />
    <Layout>
      <Switch>
        <Redirect exact from='/' to='/all-news' />
        <Route exact path='/login' component={() => <RedirectToLogin returnTo={`${window.location.origin}/`} />} />
        <Route exact path='/logout' component={() => <RedirectToLogout />} />
        <Route exact path='/all-news' component={withPageMetadata(metadata('Home'))(HomePage)} />
        <Route exact path='/:category' component={withPageMetadata(metadata('Category'))(CategoryPage)} />
        <Route path='/:category/:slug' component={withPageMetadata(metadata('Article'))(ArticlePage)} />
        <Route component={withPageMetadata(metadata('NOT FOUND'))(NotFound)} />
      </Switch>
      <IntercomWithAuth />
    </Layout>
  </MainBox>
)
