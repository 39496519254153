import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { Link } from 'react-router-dom'

const { media } = Theme

export const FeaturedSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`
export const ByCategorySection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`
export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 25px;
  & > * {
    margin-right: 15px;
  }
  @media (max-width: 767.98px) {
    justify-content: space-between;
  }
`
export const FromCategorySection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.78px;
  line-height: 24px;
  color: ${Theme.colors.primary};
  text-transform: uppercase;
`

export const ViewAllLink = styled(Link)`
  white-space: nowrap;
  ${media.mdDown} {
    margin-right: 0;
  }
`

export const Pipe = styled.div`
  box-sizing: border-box;
  height: 18px;
  width: 0.1px;
  border: 1px solid ${Theme.colors.deepGrey};
  @media (max-width: 767.98px) {
    display: none;
  }
`
export const NewsCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:after {
    content: "";
    width: 32%;
    @media (max-width: 991.98px) {
      width: 31.5%;
    }
  }
`

export const CategoryRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
`

export const NewsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 30px 0;
`

export const SectionWrapper = styled.div`
  background-color: ${props => props.bgColor ? props.bgColor : 'inherited'};
`
