import React, { Fragment } from 'react'
import 'intersection-observer'
import { head, tail } from 'ramda'
import { LoadingComponent } from '@pga/pga-component-library'
import { withAuthProvider } from '../../../../lib/auth'
import { FromCategory } from '../../Sections'
import { NewsCard } from '../../Card'
import { dataLoader } from '../../../../lib/algolia'
import { codeToName } from '../../../../lib/algolia/categoryMapper'
import LoadMore from '../../../LoadMore'
import NoNews from './NoNews'
import { getDisplayOptions, canSeePrivateNewsTypes } from '../../helpers'

const getQueries = ({ me }) => {
  const canSeePrivate = canSeePrivateNewsTypes.includes(me.type || me.role)
  const isLoggedInQuery = getDisplayOptions(canSeePrivate)

  return [
    {
      field: 'news',
      parseResult: ({ hits }) => hits,
      params: ({ category, page }) => ({
        filters: `category_primary:"${codeToName(category)}" AND ${isLoggedInQuery}`,
        hitsPerPage: 10,
        page
      })
    }
  ]
}

export class Category extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      news: null,
      moreNews: true,
      page: 0
    }

    this.loadMore = this.loadMore.bind(this)
  }

  async fetchNews (index, queries, page) {
    const { category } = this.props.match.params
    const results = await dataLoader(index, queries, { category, page })
    return results.reduce((acc, { field, parseResult, result }) => ({ ...acc, [field]: parseResult(result) }), {})
  }

  async componentDidMount () {
    window.scrollTo(0, 0)
    const { news } = await this.fetchNews(this.props.index, getQueries(this.props), this.state.page)
    this.setState({ news })
  }

  async loadMore () {
    const page = this.state.page + 1
    const { news } = await this.fetchNews(this.props.index, getQueries(this.props), page)
    this.setState({
      page,
      moreNews: !!news.length,
      news: [...this.state.news, ...news]
    })
  }

  render () {
    const { news } = this.state
    return news
      ? news.length
        ? <Fragment>
          <NewsCard item={head(news)} horizontal />
          <FromCategory items={tail(news)} />
          <LoadMore loader={this.loadMore} more={this.state.moreNews} />
        </Fragment>
        : <NoNews message='No Articles Found.' />
      : <LoadingComponent />
  }
}

export default withAuthProvider(Category)
