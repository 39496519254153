const pgaTld = window.location.hostname.replace(/^[^.]+\.(sandbox)?pga.org/, '$1pga.org') || 'pga.org'

export const loggedInOptions = [
  {
    id: 516,
    label: 'My Account',
    to: `https://account.${pgaTld}`,
    external: true
  },
  {
    id: 518,
    label: 'Jobs',
    to: `https://jobs.${pgaTld}`,
    external: true
  },
  {
    id: 519,
    label: 'Directory',
    to: `https://directory.${pgaTld}`,
    external: true
  },
  {
    id: 521,
    label: 'News',
    to: `/`,
    root: true
  },
  {
    id: 522,
    to: `https://events.${pgaTld}`,
    label: 'Events',
    external: true
  },
  {
    id: 520,
    label: 'Resources',
    to: `https://resources.${pgaTld}`,
    external: true
  },
  {
    id: 523,
    to: `https://resources.${pgaTld}/my-membership/pga-frisco`,
    label: 'PGA Frisco',
    external: true
  }
]

export const loggedOutOptions = [
  {
    id: 1,
    to: `https://www.${pgaTld}`,
    label: 'Home',
    external: true
  },
  {
    id: 2,
    to: `https://jobs.${pgaTld}`,
    label: 'Jobs',
    external: true
  },
  {
    id: 3,
    to: `https://directory.${pgaTld}`,
    label: 'Directory',
    external: true
  },
  {
    id: 4,
    label: 'News',
    to: `/`,
    root: true
  },
  {
    id: 5,
    to: `https://events.${pgaTld}`,
    label: 'Events',
    external: true
  }
]

export const loggedInMobileOptions = [
  {
    id: 5,
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  },
  {
    id: 6,
    to: 'http://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
    label: 'Shop',
    external: true,
    target: '_blank'
  },
  {
    id: 8,
    to: `${process.env.REACT_APP_AUTH_FLOW_BASE_URI}/logout`,
    label: 'Logout',
    external: true
  }
]

export const loggedOutMobileOptions = [
  {
    id: 7,
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  },
  {
    id: 8,
    to: 'https://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
    label: 'Shop',
    external: true,
    target: '_blank'
  },
  {
    id: 9,
    to: `${process.env.REACT_APP_AUTH_FLOW_BASE_URI}/login?return_to=${window.location.href}`,
    label: 'Login',
    external: true
  },
  {
    id: 10,
    to: `https://account.${pgaTld}/signup`,
    label: 'Create Account',
    external: true
  }
]

export const dropdownLinks = [
  {
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  }
]
