import moment from 'moment'

export const urlForImage = img => `${process.env.REACT_APP_NEWS_BASE_URI}${img}`

export const formatDate = date => moment.utc(date, 'YYYY-MM-DD HH:mm Z').format('ll')
export const formatFullDate = date => moment.utc(date, 'YYYY-MM-DD HH:mm Z').format('MMMM Do, YYYY')

export const capitalize = sentence =>
  sentence
    .trim()
    .split(' ')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')

export const detailUrl = (category, slug) => `/${category}/${slug}`

export const canSeePrivateNewsTypes = ['MB', 'AP', 'ST', 'STAFF', 'MEMBER', 'PGA_MEMBERSHIP_PROGRAM_USER']

export function getDisplayOptions (loggedIn) {
  if (loggedIn) {
    return '(display_for:both OR display_for:"logged in only")'
  }
  return '(display_for:both OR display_for:"public only")'
}
