import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
const { media } = Theme

const Wrapper = styled.div`
  background-color: #f4f4f5;
  display: flex;
  justify-content: center;
  height: 100%;
  width: ${props => props.horizontal ? '60%' : '100%'};
  height: ${props => props.horizontal ? 'auto' : '50%'};
  ${media.lgDown} {
    height: ${props => props.horizontal ? '100%' : '42%'};
  }
  ${media.mdDown} {
    height: 65%;
    width: 100%;
  }
  ${media.smDown} {
    height: 55%;
    width: 100%;
  }
`

export const Loader = styled.div`
  position: relative;
  width: 200px;
  transform: scale(.5);
  div {
    position: absolute;
    width: 10px;
    height: 50px;
    top: 35%;
    -webkit-animation: lds-bars 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-bars 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    left: 35%;
    background: #bac3cd;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  div:nth-child(2) {
    left: 45%;
    background: #bac3cd;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    left: 55%;
    background: #bac3cd;
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  div:nth-child(4) {
    left: 65%;
    background: #bac3cd;
  }
  @keyframes lds-bars {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

Loader.displayName = 'Loader'

export default ({ horizontal }) => (
  <Wrapper horizontal={horizontal}>
    <Loader>
      <section>
        <div />
        <div />
        <div />
        <div />
      </section>
    </Loader>
  </Wrapper>
)
