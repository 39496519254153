import LinesEllipsis from 'react-lines-ellipsis'
import styled from 'styled-components'
import responsiveEllipsisHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveEllipsis = responsiveEllipsisHOC()(LinesEllipsis)

const ResponsiveEllipsisStyled = styled(ResponsiveEllipsis)`
  white-space: pre-wrap;
`

export default ResponsiveEllipsisStyled
