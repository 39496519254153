import React from 'react'
import { Container } from 'reactstrap'
import { Theme } from '@pga/pga-component-library'
import { initIndex } from '../../lib/algolia'
import NewsHome from './Pages/Home'
import Category from './Pages/Category'
import Article from './Pages/Article'
import { NewsPageContainer, SectionWrapper } from './Sections/styled'
import SubNavigation from '../SubNavigation'

const resourceNewsIndex = initIndex(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  process.env.REACT_APP_ALGOLIA_INDEX_NAME
)

export const PageContainer = ({ children, bgColor }) => <SectionWrapper bgColor={bgColor}>
  <SubNavigation bgColor={bgColor} />
  <NewsPageContainer>
    <Container>
      {children}
    </Container>
  </NewsPageContainer>
</SectionWrapper>

export const ArticlePage = (props) => <PageContainer bgColor={Theme.colors.white}><Article index={resourceNewsIndex} {...props} /></PageContainer>

export const CategoryPage = (props) => <PageContainer><Category index={resourceNewsIndex} {...props} /></PageContainer>

export const HomePage = (props) => <PageContainer><NewsHome index={resourceNewsIndex} {...props} /></PageContainer>
