import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const { media } = Theme
const { lightBlue, white, primary, gold } = Theme.colors
const { Montserrat, PlayfairDisplay } = Theme.fonts

export const HeroImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: ${props => `url('${props.img}')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 485px;
  color: ${white};
  padding: 30px;
  margin-bottom: 25px;
  ${media.mdDown} {
    height: 305px;
    span {
      display: none;
    }
  }
  ${media.smDown} {
    height: 205px;
  }
  ${media.mdOnly} {
    height: 365px;
  }
`

export const Category = styled.span.attrs({
  className: 'hero-category'
})`
  display: flex;
  align-self: flex-start;
  flex-grow: 0;
  text-transform: uppercase;
  font-family: ${Montserrat};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${white};
  background-color: ${lightBlue};
  padding: 6px 15px;
  margin-bottom: 5px;
`
export const HeroTitle = styled.h1`
  width: 75%;
  margin-left: 10px;
  font-family: ${PlayfairDisplay};
  font-weight: 700;
  span {
    font-family: ${PlayfairDisplay};
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.28px;
    line-height: 54px;
    padding: .2rem;
    background: rgba(0, 35, 75, 0.84);
    box-shadow: 10px 0 0 rgba(0, 35, 75, 0.84), -10px 0 0 rgba(0, 35, 75, 0.84);
  }
  @media (max-width: 767.98px) {
    font-size: 19px;
    letter-spacing: -0.13px;
    line-height: 23px;
    color: ${primary};
    width: 100%;
    margin-left: 0;
  }
  ${media.mdOnly} {
    font-size: 30px;
  }
`

export const ContentBox = styled.section`
  padding: 10px 45px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  div.layout-article {
    h1, h2, h3, h4, h5, h6 {
      color: ${primary};
      margin: 1.5rem 0;
    }
    
    a:not(:hover) {
      color: ${lightBlue};
    }

    p img {
      max-width: 100%;
      height: auto;
    }
    div.row section {
      img {
        width: auto;
        height: 225px;
      }
      @media(max-width: 991.98px) {
        img {
          max-width: 100%;
          height: auto;
        } 
      }
      span {
        font-size: 16px;
        color: ${primary};
        font-family: ${Montserrat};
        display: block;
        margin: 16px 0;
      }
    } 

    table {
      width: 100%;
      margin-bottom: 1rem;
      @media (max-width: 575px) {
        display: block;
        overflow-x: auto;
      }
    }
    
    ol, ul, p, li, table {
      color: ${primary};
      font-size: 14px;
      font-weight: 500;
      line-height: 27px;
      font-family: ${Montserrat};
    }
    
    li {
      padding-bottom: .5rem;
    }
    .small {
      font-size: 11px;
      line-height: 13px;
    }
  }
`

export const Date = styled.div`
  display: flex;
  align-items: center;
  color: ${gold};
  font-family: ${Montserrat};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  padding: 10px 0;
  ${media.mdDown} {
    font-size: 14px;
  }
`

export const InfoBarBox = styled.section`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-content: center;
  height: 50px;
  margin: 20px 0 20px 0;
  .hero-category {
    position: absolute;
    top: -41px;
  }
  @media (max-width: 767.98px) {
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    padding: 10px;
    margin: 0 0 20px 0;
  }
`
