import React, { Fragment } from 'react'
import { compose, reduce, sortBy, isEmpty } from 'ramda'
import { LoadingComponent } from '@pga/pga-component-library'
import { withAuthProvider } from '../../../../lib/auth'
import { parseHitHtml } from './utils'
import { Hero, Content, InfoBar } from './parts'
import { dataLoader } from '../../../../lib/algolia'
import { codeToName } from '../../../../lib/algolia/categoryMapper'
import NoNews from '../Category/NoNews'
import { canSeePrivateNewsTypes } from '../../helpers'

const mergeArticleRecords = compose(
  reduce(
    (acc, article) => {
      return {
        ...acc,
        ...article,
        html: acc.html.concat(article.html)
      }
    }, { html: '' }
  ),
  sortBy(article => article.custom_ranking.position)
)

const queries = [
  {
    field: 'article',
    parseResult: ({ hits }) => mergeArticleRecords(hits),
    params: ({ category, slug }) => {
      return ({
        filters: `category_primary:"${codeToName(category)}" AND slug:"${slug}"`,
        distinct: false,
        hitsPerPage: 1000
      })
    }
  }
]

export const ArticleDetail = ({ article }) => (
  <Fragment>
    <Hero title={article.title} img={article.hero_image} category={article.category_primary} />
    <InfoBar date={article.publish_date} title={article.title} category={article.category_primary} />
    <Content content={article.html} />
  </Fragment>
)

export const hasAuthAccess = ({ article, me }) => article.display_for === 'logged in only' ? canSeePrivateNewsTypes.includes(me.type || me.role) : true

export const CheckAuth = ({ me, isLoggedIn, article }) => {
  return (
    hasAuthAccess({ article, me })
      ? <ArticleDetail article={article} />
      : <NoNews message='You must be logged in to view this article.' loginLink />
  )
}

export class ArticlePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      article: null
    }
  }

  async componentDidMount () {
    window.scrollTo(0, 0)
    const { index, match: { params: { category, slug } } } = this.props
    const results = await dataLoader(index, queries, { category, slug })

    const parsedResults = parseHitHtml(results)

    this.setState(parsedResults.reduce((acc, { field, parseResult, result }) => ({ ...acc, [field]: parseResult(result) }), {}))
  }

  render () {
    const { article } = this.state
    const { me, isLoggedIn, authReady } = this.props
    if (!authReady || !article) {
      return <LoadingComponent />
    }
    return isEmpty(article.html)
      ? <NoNews message='No Articles Found.' />
      : <CheckAuth {...{ me, isLoggedIn, article }} />
  }
}

export default withAuthProvider(ArticlePage)
