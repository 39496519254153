import React from 'react'
import Loading from './Loading'

export default class LoadMore extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  componentDidMount () {
    const intersectionObserver = new window.IntersectionObserver((entries) => {
      if (!this.props.more || entries[0].intersectionRatio <= 0) {
        this.setState({
          loading: false
        })
        return
      }
      this.setState({
        loading: true
      })
      this.props.loader()
    })
    intersectionObserver.observe(document.querySelector('.load-more-container'))
  }

  render () {
    const loading = (this.state.loading && this.props.more)
    return <div className='load-more-container'>{loading ? <Loading /> : null}</div>
  }
}
