export const navigation = [
  {
    id: 'all-news',
    label: 'All News',
    to: '/all-news'
  },
  {
    id: 'partnerships',
    label: 'Partnerships',
    to: '/partnerships'
  },
  {
    id: 'governance',
    label: 'Governance',
    to: '/governance'
  },
  {
    id: 'championships-and-events',
    label: 'Championships & Events',
    to: '/championships-and-events'
  },
  {
    id: 'awards',
    label: 'Awards',
    to: '/awards'
  },
  {
    id: 'industry-news',
    label: 'Industry News',
    to: '/industry-news'
  },
  {
    id: 'members',
    label: 'Members',
    to: '/members'
  },
  {
    id: 'pga-reach',
    label: 'PGA REACH',
    to: '/pga-reach'
  },
  {
    id: 'global',
    label: 'GLOBAL',
    to: '/global'
  }
]
