import React from 'react'
import styled from 'styled-components'
import { SecondaryNav, IframeRolex } from '@pga/pga-component-library'
import { navigation } from './NavOptions'

export const NavFrame = styled.div`
  z-index:1000;
  @media (max-width: 991px) {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 998px) {
    width: 100%;
    box-shadow: ${props => props.bgColor ? '0 5px 10px 0 rgba(0,35,75,.15);' : 'unset'};
    }
`

export default ({ me, loading, bgColor }) =>
  !loading
    ? <NavFrame bgColor={bgColor}>
      <SecondaryNav options={navigation} title='News' menuTitle='News Menu' />
      <IframeRolex mobile />
    </NavFrame>
    : null
