import React, { Fragment } from 'react'
import { groupBy, take, slice, sort } from 'ramda'
import MediaQuery from 'react-responsive'
import { LinkIcon } from '@pga/pga-component-library'
import { NewsCard, CardList } from '../Card'
import { CardImage, Tag, LinkFrame, HeroTitle } from '../Card/styled'
import { urlForImage, detailUrl } from '../helpers'
import { FeaturedSection, TitleBox, Title, ViewAllLink, Pipe, NewsCardRow, CategoryRow, ByCategorySection } from './styled'
import { nameToCode, displayOrder } from '../../../lib/algolia/categoryMapper'

export const TopStory = ({ item: { title, hero_image: heroImage, category_primary: category, objectID, slug } }) => <CardImage img={urlForImage(heroImage)}>
  <LinkFrame to={detailUrl(nameToCode(category), slug)}>
    <Fragment>
      <Tag>TOP STORY</Tag>
    </Fragment>
    <HeroTitle>{title}</HeroTitle>
  </LinkFrame>
</CardImage>

export const Featured = ({ items }) => <FeaturedSection>
  {
    items.slice(0, 2).map(
      ({ title, hero_image: heroImage, category_primary: category, objectID, slug }) => <CardImage md key={objectID} img={urlForImage(heroImage)}>
        <LinkFrame md to={detailUrl(nameToCode(category), slug)}>
          <Fragment>
            <Tag>featured</Tag>
          </Fragment>
          <HeroTitle md>{title}</HeroTitle>
        </LinkFrame>
      </CardImage>)
  }
</FeaturedSection>

const ViewAll = ({ category }) => <ViewAllLink to={`/${category}`}>View all<LinkIcon /></ViewAllLink>

const NewsCards = ({ categoryNews }) => <MediaQuery maxWidth={767}>
  {(matches) => {
    const toTake = matches ? 1 : 2
    const listCount = matches ? 6 : 5
    return (
      <Fragment>
        {
          take(toTake, categoryNews).map(item => <NewsCard key={item.objectID} item={item} />)
        }
        <CardList items={slice(toTake, toTake + listCount, categoryNews)} />
      </Fragment>
    )
  }}
</MediaQuery>

export const sortByCategories = (categories) =>
  sort(
    (a, b) => displayOrder.indexOf(a) - displayOrder.indexOf(b),
    Object.keys(categories)
  )

export const ByCategory = ({ items }) => {
  const categories = groupBy(newsItem => newsItem.category_primary, items)
  return <ByCategorySection>
    {
      sortByCategories(categories).map((category) => {
        return <CategoryRow key={category}>
          <TitleBox>
            <Title>{category}</Title>
            <Pipe />
            <ViewAll category={nameToCode(category)} />
          </TitleBox>
          <NewsCardRow>
            <NewsCards categoryNews={categories[category]} />
          </NewsCardRow>
        </CategoryRow>
      })
    }
  </ByCategorySection>
}

export const FromCategory = ({ items }) => <NewsCardRow>{
  items.map(item => <NewsCard key={item.objectID} item={item} />)
}</NewsCardRow>
