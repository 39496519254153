import React, { Fragment } from 'react'
import { LoadingComponent } from '@pga/pga-component-library'
import { withAuthProvider } from '../../../lib/auth'
import { TopStory, Featured, ByCategory } from '../Sections'
import { dataLoader } from '../../../lib/algolia'
import { categories } from '../../../lib/algolia/categoryMapper'
import { canSeePrivateNewsTypes, getDisplayOptions } from '../helpers'
import { pathOr } from 'ramda'

const queryByCategory = query => categories.reduce((acc, category) => ([
  ...acc,
  {
    field: 'byCategory',
    parseResult: ({ hits }) => hits,
    params: () => ({
      filters: `category_primary:"${category.name}" AND ${query}`,
      hitsPerPage: 7
    })
  }
]), [])

const getQueries = ({ me }) => {
  const type = pathOr('', ['type'], me)
  const role = pathOr('', ['role'], me)
  const canSeePrivate = canSeePrivateNewsTypes.includes(type || role)
  const displayOptionsFragment = getDisplayOptions(canSeePrivate)

  return [
    {
      field: 'topStory',
      parseResult: ({ hits: [topStory] }) => topStory,
      params: () => ({
        filters: `top_story:true AND ${displayOptionsFragment}`,
        hitsPerPage: 1
      })
    },
    {
      field: 'featured',
      parseResult: ({ hits }) => hits,
      params: () => ({
        filters: `top_story:false AND featured:true AND ${displayOptionsFragment}`,
        hitsPerPage: 2
      })
    },
    ...queryByCategory(displayOptionsFragment)
  ]
}
export class NewsHome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      topStory: null,
      featured: [],
      byCategory: [],
      isLoaded: false
    }
  }

  async componentDidMount () {
    window.scrollTo(0, 0)
    const results = await dataLoader(this.props.index, getQueries(this.props))
    const resultParsed = results.reduce(
      (acc, { field, parseResult, result }) => {
        return {
          ...acc,
          [field]: Array.isArray(acc[field]) ? [...acc[field], ...parseResult(result)] : parseResult(result)
        }
      }, {}
    )
    this.setState({ ...resultParsed, isLoaded: true })
  }
  render () {
    const { topStory, featured, byCategory, isLoaded } = this.state

    return isLoaded
      ? <Fragment>
        { topStory ? <TopStory item={topStory} /> : null }
        { featured ? <Featured items={featured} /> : null }
        { byCategory ? <ByCategory items={byCategory} /> : null }
      </Fragment>
      : <LoadingComponent />
  }
}

export const NewsHomeWrapper = (props) => {
  return props.authReady ? <NewsHome {...props} /> : <LoadingComponent />
}

export default withAuthProvider(NewsHomeWrapper)
