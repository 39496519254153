import React from 'react'
import { Link } from 'react-router-dom'
import { CardText, CardBody } from 'reactstrap'
import { urlForImage, formatDate, capitalize, detailUrl } from '../helpers'
import { CardListBox, ListGroup, ListGroupItem, Card, DateTagBox, DateTag, NewsTitle, FakeLink, CardTopImage } from './styled'
import { nameToCode } from '../../../lib/algolia/categoryMapper'
import { LinkIcon } from '@pga/pga-component-library'
import ResponsiveLineEllipsis from '../../ResponsiveLineEllipsis'
import ImageLoader from './ImageLoader'
import Loader from './Loader'

export const MoreNews = ({ category, code }) => (
  <ListGroupItem noborder centered tag={Link} to={`/${code}`}>
    {`More ${capitalize(category)} News`} <LinkIcon />
  </ListGroupItem>
)

const buildNewsItem = ({ title, category_primary: category, objectID, slug }) => (
  <ListGroupItem tag={Link} key={objectID} to={detailUrl(nameToCode(category), slug)}>
    <ResponsiveLineEllipsis
      text={title}
      maxLine='2'
      ellipsis='...'
    />
  </ListGroupItem>
)

export const CardList = ({ items }) => items.length
  ? <CardListBox>
    <ListGroup flush>
      {items.map(buildNewsItem)}
    </ListGroup>
    <MoreNews code={items.length && nameToCode(items[0].category_primary)} category={items[0].category_primary} />
  </CardListBox>
  : null

export const NewsCard = ({ item: { title, hero_image: heroImage, publish_date: date, category_primary: category, objectID, slug }, horizontal }) => {
  return (
    <Card horizontal={horizontal} tag={Link} to={detailUrl(nameToCode(category), slug)}>
      <ImageLoader src={urlForImage(heroImage)} delay={500}>
        {({ loading, src }) => {
          return loading
            ? <Loader horizontal={horizontal} />
            : <CardTopImage img={src} alt='news image' />
        }}
      </ImageLoader>
      <CardBody>
        <div>
          <DateTagBox>
            <DateTag>{formatDate(date)}</DateTag>
          </DateTagBox>
          <NewsTitle>{title}</NewsTitle>
        </div>
        <CardText>
          <FakeLink>Read Story</FakeLink>
        </CardText>
      </CardBody>
    </Card>
  )
}
