import React from 'react'
import styled from 'styled-components'

export const ShareBox = styled.div.attrs()`
  display: flex;
  align-items: center;
  padding: 15px 0;
`

export default class Share extends React.Component {
  async componentDidMount () {
    await this.props.loader(this.props.toolUrl)
  }

  render () {
    return <ShareBox><div className='addthis_inline_share_toolbox' /></ShareBox>
  }
}
