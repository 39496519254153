import React from 'react'
import styled from 'styled-components'

export const LoadingComponentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  z-index: 8888;
  text-align: center;
  width: 100%;

  .loadingAnimation {
    margin-top: 40px;
    ${props => props.small && `margin-top: 28px;`};
  }

  .loadingAnimation > div {
    width: 50px;
    height: 6px;
    background: #b5985b;
    display: inline-block;
    animation: loading 1.1s infinite ease-in-out both;
    margin-right: 6px;
    ${props => props.small && `width: 40px; height: 4px; margin-right: 4px;`};

    @keyframes loading {
      20% {
        opacity: 0.2;
      }
      40% {
        opacity: 0.4;
      }
      60% {
        opacity: 0.6;
      }
      80% {
        opacity: 0.8;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .loadingAnimation .loadingAnimation__item1 {
    animation-delay: -0.8s;
  }

  .loadingAnimation .loadingAnimation__item2 {
    animation-delay: -0.6s;
  }

  .loadingAnimation .loadingAnimation__item3 {
    animation-delay: -0.4s;
  }

  .loadingAnimation .loadingAnimation__item4 {
    animation-delay: -0.2s;
  }

  .loadingAnimation .loadingAnimation__item5 {
    animation-delay: -0s;
  }
`

LoadingComponentContainer.displayName = 'LoadingComponentContainer'

export default props => (
  <LoadingComponentContainer {...props}>
    <div className='loadingAnimation'>
      <div className='loadingAnimation__item1' />
      <div className='loadingAnimation__item2' />
      <div className='loadingAnimation__item3' />
      <div className='loadingAnimation__item4' />
      <div className='loadingAnimation__item5' />
    </div>
  </LoadingComponentContainer>
)
