import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { Card as RSCard, ListGroup as RSListGroup, ListGroupItem as RSListGroupItem, CardTitle as RSCardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'

const { media } = Theme
const { gold, lightBlue, primary, darkgrey, white, primaryShadow, primaryOpacity, primaryOpaque } = Theme.colors
const { Montserrat, PlayfairDisplay } = Theme.fonts

export const DateTagBox = styled.div`
  display: flex;
  margin-top: -35px;
  margin-bottom: 10px;
`
export const DateTag = styled.span`
  flex-grow: 0;
  background-color: ${lightBlue};
  color: ${white};
  font-family: ${Montserrat};
  font-size: 11px;
  font-weight: 600;
  line-height: 8px;
  padding: 10px;
  text-transform: uppercase;
`

export const NewsTitle = styled(RSCardTitle)`
  color: ${primary};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 25px;
  font-family: ${PlayfairDisplay};
  ${media.lgDown} {
    font-size: 16px;
    letter-spacing: -0.12px;
    line-height: 24px;
  }
`

export const Card = styled(RSCard)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  display: flex;
  border-radius: 0;
  flex-basis: 32%;
  height: 352px;
  border: 1px solid ${darkgrey};
  background-color: ${white};
  box-shadow: 0 5px 10px 0 ${primaryShadow};
  margin-bottom: 20px;
  flex-direction: ${props => props.horizontal ? 'row' : 'column'};
  div.top-image {
    width: ${props => props.horizontal ? '60%' : '100%'};
    height: ${props => props.horizontal ? 'auto' : '50%'};
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${props => props.horizontal ? `
      padding: 30px;`
    : ''}
  }
  ${DateTagBox} {
    ${props => props.horizontal ? `
      margin-top: 0;
      margin-bottom: 10px;`
    : ''}
  }
  ${media.mdOnly} {
    flex-basis: 31.5%;
    height: ${props => props.horizontal ? '270px' : '320px'};
    div.top-image {
      height: ${props => props.horizontal ? '100%' : '42%'};
    }
  }
  ${media.mdDown} {
    height: 380px;
    flex-direction: column;
    flex-basis: 100%;
    div.top-image {
      width: 100%;
      height: 65%;
    }
    .card-body {
      padding: 20px;
    }
    ${DateTagBox} {
      margin-top: -35px;
    }
  }
  ${media.smDown} {
    height: 300px;
    div.top-image {
      width: 100%;
      height: 55%;
    }
  }
`

export const ListGroup = styled(RSListGroup)`
  display: flex;
  flex-grow: 1;
  a {
    color: ${primary};
    font-family: ${Montserrat};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.54px;
    line-height: 19px;
    ${media.mdDown} {
      font-size: 12px;
      letter-spacing: -0.46px;
      font-weight: 600;
    }
  }
`

export const ListGroupItem = styled(RSListGroupItem)`
  display: flex;
  align-items: center;
  padding: 0;
  height: 60px;
  ${props => props.noborder ? 'border: none;' : ''}
  justify-content: ${props => props.centered ? ' center' : 'flex-start'};
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 50px;
  }
`

export const CardListBox = styled(Card).attrs({
  className: 'card-list-box'
})`
  padding: 12px 20px 0px 20px;
  @media (max-width: 767.98px) {
    height: auto;
    margin-top: -21px;
  }
`

export const CardTopImage = styled.div.attrs({
  className: 'top-image'
})`
  display: flex;
  background-image: ${props => `url('${props.img}')`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 175px;
`

export const CardImage = styled.div`
  display: flex;
  flex-basis: ${props => props.md ? '49' : '100'}%;
  flex-direction: column;
  justify-content: flex-end;
  background-image: ${props => `url('${props.img}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => props.md ? '275' : '485'}px;
  color: ${white};
  margin-bottom: 25px;
  ${media.mdDown} {
    height: 305px;
  }
  ${media.mdOnly} {
    ${props => props.md ? `height: 215px;` : `height: 315px;`}
  }
`

export const LinkFrame = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: ${props => props.md ? '25' : '35'}px;
  color: ${white};
  background: linear-gradient(0deg, ${primary}, ${primary} 12%, ${primaryOpacity} 20%, ${primaryOpaque} 45%, ${primaryOpaque});
  &:hover {
    text-decoration: none;
    color: ${white};
  }
  @media (max-width: 767.98px) {
    padding: 15px;
  }
`

export const Tag = styled.span`
  flex-grow: 0;
  align-self: flex-start;
  display: flex;
  text-transform: uppercase;
  font-family: ${Montserrat};
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2.1px;
  line-height: 14px;
  border-bottom: 4px solid ${gold};
  padding-bottom: 4px;
`

export const HeroTitle = styled.div`
  font-family: ${PlayfairDisplay};
  font-weight: bold;
  margin-top: 7px;
  ${props => props.md
    ? 'font-size: 28px; letter-spacing: -0.21px; line-height: 34px;'
    : 'font-size: 46px; letter-spacing: -0.34px; line-height: 56px;'}
  ${media.mdDown} {
    font-size: 19px;
    letter-spacing: -0.13px;
    line-height: 23px;
  }
  ${media.mdOnly} {
    ${props => props.md
    ? 'font-size: 18px; letter-spacing: -0.14px; line-height: 25px;'
    : 'font-size: 30px; letter-spacing: -0.22px; line-height: 35px;'}
  }
`

export const FakeLink = styled.span`
  color: ${lightBlue};
  font-family: ${Montserrat};
  font-weight: 500;
  line-height: 15px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
