import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import 'font-awesome/css/font-awesome.css'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { AuthProvider } from './lib/auth'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App/App'
import { unregister } from './registerServiceWorker'
import apolloClient from './apolloClient'
import './polyfills'
import './index.css'

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
)
unregister()
