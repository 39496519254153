import algoliasearch from 'algoliasearch'

export const initIndex = (appId, apiKey, indexName) => algoliasearch(appId, apiKey).initIndex(indexName)

export const dataLoader = (index, queries, qparams = {}) =>
  Promise.all(queries.map(async ({ params, field, parseResult }) =>
    Promise.resolve({ field, parseResult, result: await index.search(params(qparams)) })))
    .catch(err => {
      throw err
    })
