import React from 'react'
import { Theme } from '@pga/pga-component-library'
import styled, { ThemeProvider } from 'styled-components'
import Header from '../Header'
import PgaFooter from '../Footer'

const ContentWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const PageContainer = styled.section`
  flex-grow: 1;
`

export const Layout = ({ children, loading }) => (
  <ThemeProvider theme={Theme}>
    <ContentWrapper>
      <Header />
      <PageContainer>
        {children}
      </PageContainer>
      <PgaFooter />
    </ContentWrapper>
  </ThemeProvider>
)

export default Layout
