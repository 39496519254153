import { compose, find, prop, propEq, flip, toLower } from 'ramda'

export const displayOrder = [
  'Members',
  'Championships & Events',
  'Partnerships',
  'Governance',
  'PGA REACH',
  'Industry News',
  'Awards',
  'Global'
]

export const categories = [

  {
    'name': 'members',
    'code': 'members'
  },
  {
    'name': 'championships & events',
    'code': 'championships-and-events'
  },
  {
    'name': 'partnerships',
    'code': 'partnerships'
  },
  {
    'name': 'governance',
    'code': 'governance'
  },
  {
    'name': 'pga reach',
    'code': 'pga-reach'
  },
  {
    'name': 'industry news',
    'code': 'industry-news'
  },
  {
    'name': 'awards',
    'code': 'awards'
  },
  {
    'name': 'global',
    'code': 'global'
  }
]

const flippedFind = flip(find)

export const nameToCode = compose(
  prop('code'),
  flippedFind(categories),
  propEq('name'),
  toLower
)

export const codeToName = compose(
  prop('name'),
  flippedFind(categories),
  propEq('code')
)
