import React from 'react'
import { withAuthProvider } from '../../lib/auth'
import { MyAccountButton, MemberDropdown } from '@pga/pga-component-library'

export const AuthHeader = ({ isLoggedIn, me, options = [], loading, meEmailMigrationData, onToggleDoNotShowAgain }) => (
  isLoggedIn
    ? <MemberDropdown {...{ me, options, loading, meEmailMigrationData, onToggleDoNotShowAgain }} />
    : <MyAccountButton />
)

export default withAuthProvider(AuthHeader)
