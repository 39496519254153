import React, { Fragment } from 'react'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { Theme, LinkIcon } from '@pga/pga-component-library'

export const Message = styled.h6`
  color: ${Theme.colors.primary};
  padding: 0.5rem 0;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
`

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  margin: 3rem auto;
  padding: 3rem 1.2rem;
  box-shadow: 0px 5px 10px 0px ${Theme.colors.primaryShadow};
  border-radius: 0;
  width: 50%;
  @media(max-width: 991.98px) {
    width: 75%;
  }
  @media(max-width: 575.98px) {
    width: 90%;
    padding: 2rem;
  }
`

const Row = styled.div.attrs({ className: 'row' })`
  justify-content: space-evenly;
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
`

const NoNews = ({ message, loginLink }) => (
  <CardWrapper>
    <Message>{message}</Message>
    <Row>
      <Link to='/all-news'>View All News <LinkIcon className='px-1' /></Link>
      {loginLink && (
        <Fragment>
          <div className='border-right' />
          <Link to='/login'>Login <LinkIcon className='px-1' /></Link>
        </Fragment>
      )}
    </Row>
  </CardWrapper>
)

NoNews.propTypes = {
  message: string.isRequired
}

export default NoNews
