import React from 'react'
import { isEmpty, compose } from 'ramda'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withAuthProvider } from '../../lib/auth'
import { Header, Nav, IconsBox, MobileNavigation, Theme, LoadingComponent, IframeRolex } from '@pga/pga-component-library'
import MediaQuery from 'react-responsive'
import { loggedInOptions, loggedOutOptions, loggedInMobileOptions, loggedOutMobileOptions, dropdownLinks } from './options'
import AuthHeader from '../AuthHeader'
import logo from '../../assets/logo.svg'
import { withEmailMigrationData, withSetEmailMigrationMutation } from './query'

const EditLink = styled(NavLink)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  position: relative;

  &.active {
    font-weight: 700;
  }

  &.active::before {
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

const LinkStyled = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -31px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

export const HeaderComp = ({ isLoggedIn, authReady, me: meData, emailMigrationData, emailMigrationMutation }) => {
  const me = isEmpty(meData) ? null : meData
  const logoLink = isLoggedIn ? 'https://account.pga.org' : 'https://beta.pga.org'
  const options = isLoggedIn ? loggedInOptions : loggedOutOptions
  const mobileOptions = isLoggedIn ? options.concat(loggedInMobileOptions) : options.concat(loggedOutMobileOptions)

  const { loading, meEmailMigrationData: migrationData } = emailMigrationData || {}
  const meEmailMigrationData = migrationData || {}
  const { id, doNotShowAgain } = meEmailMigrationData

  const onToggleDoNotShowAgain = () => {
    emailMigrationMutation({ variables: { id, input: { doNotShowAgain: !doNotShowAgain } } })
  }

  if (!authReady || loading) {
    return <LoadingComponent />
  }

  return (
    <Header {...{ logo, logoLink, bgColor: Theme.colors.primary }} >
      <MediaQuery minWidth={992}>
        <Nav {...{ options, comp: LinkStyled, b: -31, l: 10 }} />
        <div className='d-flex align-items-center'>
          <IframeRolex />
          <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
        </div>
        <AuthHeader {...{ isLoggedIn, me, authReady, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <MobileNavigation {...{ linkComponent: EditLink, options: mobileOptions, me, comp: EditLink, b: -7, l: 11, isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain }} vertical />
      </MediaQuery>
    </Header>
  )
}

export default compose(
  withEmailMigrationData,
  withSetEmailMigrationMutation,
  withAuthProvider
)(HeaderComp)
