import { cond, T, path } from 'ramda'

const parseVid = hitHtml => {
  const vidSrc = splitHelper(hitHtml, 'data-video-src=')
  return '<div class="embed-responsive embed-responsive-16by9 mb-3"><iframe class="embed-responsive-item" src="' + vidSrc + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>'
}
const parseImg = hitHtml => {
  const imgSrc = splitHelper(hitHtml, 'data-image-src=')
  return '<div class="mb-3"><img class="img-fluid" src="https://resources.pga.org' + imgSrc + '"></image></div>'
}

const splitHelper = (html, condition) => {
  return html.split(condition)[1].split('"')[1]
}

const whichParse = cond([
  [html => html.includes('video-src'), parseVid],
  [html => html.includes('image-src'), parseImg],
  [T, (html) => html]
])

export const parseHitHtml = results => {
  let parsedResults = [...results]
  const hits = path(['0', 'result', 'hits'], parsedResults)

  for (const hit of hits) {
    hit.html = whichParse(hit.html)
  }

  return parsedResults
}
