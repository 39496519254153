import React, { useState, useEffect } from 'react'
import { pathOr } from 'ramda'
import { getToken, withAuthProvider } from '../../lib/auth'
import { useIntercom } from '@pga/react-intercom'
import useFetch from 'use-http'

export const IntercomWithUser = ({ authToken, setIntercomHmac, setUserId }) => {
  const headers = {
    'Authorization': `Bearer ${authToken}`
  }

  const { data = {} } = useFetch(`${process.env.REACT_APP_PLATFORM_API_ROOT}/me`, { headers }, [ authToken ])

  const { external_id: externalId } = data
  const intercomHmac = pathOr(null, ['intercom_hmacs', 'web'], data)

  useEffect(() => {
    if (externalId) {
      setUserId(externalId)
      setIntercomHmac(intercomHmac)
    }
  }, [ externalId, intercomHmac ])

  return null
}

export const Intercom = ({ isLoggedIn }) => {
  const { boot, update, isBooted } = useIntercom(process.env.REACT_APP_INTERCOM_APP_ID)
  const [userId, setUserId] = useState(null)
  const [intercomHmac, setIntercomHmac] = useState(null)
  const [authToken, setAuthToken] = useState(null)

  useEffect(() => {
    if (!isBooted) {
      boot()
    }
    if (isBooted && userId) {
      update({ user_id: userId, user_hash: intercomHmac })
    }
  }, [isBooted, userId, intercomHmac])

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken()
      setAuthToken(token)
    }
    fetchToken()
  }, [])

  return isLoggedIn ? <IntercomWithUser authToken={authToken} setIntercomHmac={setIntercomHmac} setUserId={setUserId} /> : null
}

const IntercomWithAuth = withAuthProvider(Intercom)

IntercomWithAuth.displayName = 'withAuthProvider(Intercom)'
export default IntercomWithAuth
