import React from 'react'
import MediaQuery from 'react-responsive'
import AddThisShare from '../../../AddThisShare'
import { loadScript } from '../../../../lib/helpers'
import { HeroImage, HeroTitle, Category, ContentBox, InfoBarBox, Date } from './styled'
import { urlForImage, capitalize, formatFullDate } from '../../helpers'

export const Hero = ({ title, img, category }) => (
  <HeroImage img={urlForImage(img)}>
    <Category>{capitalize(category)}</Category>
    <HeroTitle>
      <span>
        {title}
      </span>
    </HeroTitle>
  </HeroImage>
)
export const InfoBar = ({ date, category, title }) => (
  <InfoBarBox>
    <MediaQuery maxWidth={767.98}>
      <Category>{capitalize(category)}</Category>
      <HeroTitle>
        {title}
      </HeroTitle>
    </MediaQuery>
    <Date>{formatFullDate(date)}</Date>
    <AddThisShare loader={loadScript} toolUrl={process.env.REACT_APP_SHARE_INLINE_TOOL_URL} />
  </InfoBarBox>
)
export const Content = ({ content }) => (
  <ContentBox>
    <div className='layout-article' dangerouslySetInnerHTML={{ __html: content }} />
  </ContentBox>
)
