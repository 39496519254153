import React from 'react'
import { string, number, func } from 'prop-types'

export default class ImageLoader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      src: null,
      loading: true
    }
    this.setImage = this.setImage.bind(this)
    this.loadImage = this.loadImage.bind(this)
  }
  componentDidMount () {
    this.loadImage(this.props)
  }
  componentWillUnmount () {
    if (this.image) {
      this.image.onload = null
      this.image.onerror = null
    }
  }
  componentDidUpdate (prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({ loading: true }, () => this.loadImage(this.props))
    }
  }
  setImage (src) {
    this.setState({
      src,
      loading: false
    })
  }
  loadImage ({ src, delay }) {
    const image = new window.Image()
    image.src = src
    image.onload = () => {
      if (delay) {
        setTimeout(() => this.setImage(src), delay)
      } else {
        this.setImage(src)
      }
    }
    image.onerror = () => {
      this.setState({
        loading: false
      })
    }
    this.image = image
  }
  render () {
    const { children } = this.props

    if (!children || typeof children !== 'function') {
      throw new Error(`ImageLoader component requires children prop function.`)
    }

    return children(this.state)
  }
}

ImageLoader.propTypes = {
  src: string,
  delay: number,
  children: func
}
